import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    page: {
      fontFamily: 'Helvetica',
      padding: 20,
    },
    header: {
      marginBottom: 20,
    },
    title: {
      fontSize: 12,
      fontWeight: 'bold',
    },
    titleMayor: {
        fontSize: 16,
        fontWeight: 'bold',
      },
      titleEnc: {
        fontSize: 18,
        fontWeight: 'bold',
        textAlign: "center"
      },
    details: {
      marginBottom: 20,
      border: '1px solid #000', // Añade un borde a la sección de detalles
      padding: 10,
    },
    item: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      borderBottom: '1px solid #ccc', // Añade un borde inferior a cada elemento
      padding: 5,
    },
    itemName: {
      fontSize: 10,
      textAlign: 'left',
      justifyContent: 'space-between',
    },
    itemEncabezado: {
        fontSize: 12,
      },
    itemPrice: {
      fontSize: 10,
      fontWeight: 'bold',
    },
    itemTotal: {
        fontSize: 12,
        fontWeight: 'bold',
        textAlign: 'right',
      },
       table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
  tableCell: {
    margin: 'auto',
    borderBottomWidth: 1,
    borderRightWidth: 1,
    padding: 5,
  },
  tableHeader: {
    margin: 'auto',
    padding: 5,
    fontWeight: 'bold',
  },
  });

const InvoicePDF = ({ encabezado, cli, pro, cen, detalles,Totales , Unidades}) => {
   
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
        <Text style={styles.titleEnc}>FROZEN SAS</Text>
          <Text style={{fontSize: 14,textAlign: "right"}}>Orden de Compra No. {encabezado.id}</Text>
          <Text style={styles.title}>Fecha de Registro: {encabezado.date}</Text>
          <Text style={styles.title}>Fecha de Entrega: {encabezado.duedate}</Text>
          <Text style={styles.title}>Cliente: {cli.name}</Text>
          <Text style={styles.title}>Proveedor: {pro.name}</Text>
          <Text style={styles.title}>Almacen: {cen.code} - {cen.name}</Text>
          <Text style={styles.title}>Direccion de entrega: {cen.address} Tel: {cen.phone}</Text>
        </View>
        <View style={styles.details}>
          <Text style={styles.title}>Detalles de la orden</Text>
          <View  style={{flexDirection:'row',backgroundColor: '#A9D0F5',border:1}}>
               <View style={{width:50,fontSize: 8}}><Text>Codigo</Text></View>
               <View style={{width:250,fontSize: 8}}><Text>Descripcion</Text></View>
               <View style={{width:250,fontSize: 8,textAlign: "left"}}><Text>Observaciones</Text></View>
               <View style={{width:100,fontSize: 8,textAlign: "left"}}><Text>Cantidad</Text></View>
               <View style={{width:50,fontSize: 8,textAlign: "right"}}><Text>Vlr Und</Text></View>
               <View style={{width:50,fontSize: 8,textAlign: "right"}}><Text>Total</Text></View>
            </View>
          {detalles.map((item, index) => (
            <View style={{flexDirection:'row',border:1,padding: 5}} key={index}>
               <View style={{width:50,textAlign: "left",fontSize: 7}}> <Text>{item.item.code}</Text> </View>
               <View style={{width:250,textAlign: "left",fontSize: 7}}><Text>{item.item.description}</Text> </View>
               <View style={{width:250,textAlign: "left",fontSize: 7}}><Text>{item.observation} </Text> </View>
               <View style={{width:100,textAlign: "left",fontSize: 7}}><Text>{item.amount}
                
                 {
                                                                Unidades.map((it) => (
                                                                    <Text key={it.id}> {item.unidadmedida===it.id?it.name:''}</Text>
                                                                ))
                                                            }
                 </Text> </View>
               <View style={{width:50,textAlign: "right",fontSize: 7}}><Text>${(item.value).toLocaleString('es-ES')}</Text> </View>
               <View style={{width:50,textAlign: "right",fontSize: 7}}><Text>${(item.value*item.amount).toLocaleString('es-ES')}</Text> </View>
            </View>
          ))}
          <View style={{flexDirection:'row',border:1,padding: 5}}>
             <View style={{width:500,fontSize: 8,textAlign: "right"}}><Text>Totales:</Text></View>
              <Text style={{width:100,fontSize: 8,textAlign: "right"}}>${Totales.toLocaleString('es-ES')}</Text>
            </View>
        </View>
      </Page>
    </Document>
  );
};

export default InvoicePDF;