import React from 'react';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight, faEdit, faFilter, faPlus, faTrashAlt, faCheck, faPowerOff } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import Api from "../../services/Api";


class Listar extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {
        load: '',
        datosCargados: false,
        Registros: [],
        Modulos: [],
        Marcas: [],
        Categoria: [],
        Categoria2: [],
        Tipos: [],
        Company: [],
        Area: [],
        Subarea: [],
        Stibas: []
    }
    borrarRegistros = (id) => {
        Swal.fire({
            title: 'Está seguro de Eliminar el registro?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Borrar',
            denyButtonText: `Cancelar`,
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(Api + "configuration/item/" + id)
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta);
                        Swal.fire('Borrado!', 'Se ha borrado con exito', 'success')
                        this.Listar(1);
                    })
                    .catch(console.log);
            } else if (result.isDenied) {
                Swal.fire('Cancelado', '', 'info')
            }
        })
    };

    Listar(page) {
        Swal.showLoading();
        let bname = document.getElementById('bnombre').value;

        axios.get(Api + "configuration/item?page=" + page + "&search=" + bname, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((datosRespuesta) => {
                Swal.close();

                console.log(datosRespuesta.data);
                this.setState({ datosCargados: true, load: '', Registros: datosRespuesta.data.items, pagina: datosRespuesta.data.total, ultimo: datosRespuesta.data.last_page, actual: datosRespuesta.data.page });
            })
            .catch(console.log);
        //window.location.href = '/'; window.localStorage.clear();
    }
    Listarcategoria(page) {

        axios.get(Api + "parameters/category?page=" + page, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((datosRespuesta) => {
                Swal.close();

                console.log(datosRespuesta.data);
                this.setState({ Categoria: datosRespuesta.data.items });
            })
            .catch(console.log);
        //window.location.href = '/'; window.localStorage.clear();
    }
    Listarstibas(page) {

        axios.get(Api + "configuration/stowage?page=" + page, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((datosRespuesta) => {
                Swal.close();

                console.log(datosRespuesta.data);
                this.setState({ Stibas: datosRespuesta.data.items });
            })
            .catch(console.log);
        //window.location.href = '/'; window.localStorage.clear();
    }
    Listarcategoria2(page) {

        axios.get(Api + "parameters/subcategory?page=" + page, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((datosRespuesta) => {
                Swal.close();

                console.log(datosRespuesta.data);
                this.setState({ Categoria2: datosRespuesta.data.items });
            })
            .catch(console.log);
        //window.location.href = '/'; window.localStorage.clear();
    }
    ListarTipos(page) {

        axios.get(Api + "parameters/type?page=" + page, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((datosRespuesta) => {
                Swal.close();

                console.log(datosRespuesta.data);
                this.setState({ Tipos: datosRespuesta.data.items });
            })
            .catch(console.log);
        //window.location.href = '/'; window.localStorage.clear();
    }
    Listarcompany(page) {

        axios.get(Api + "parameters/company?page=" + page, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((datosRespuesta) => {
                Swal.close();

                console.log(datosRespuesta.data);
                this.setState({ Company: datosRespuesta.data.items });
            })
            .catch(console.log);
        //window.location.href = '/'; window.localStorage.clear();
    }
    Listarmarcas(page) {

        axios.get(Api + "parameters/brand?page=" + page, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((datosRespuesta) => {
                Swal.close();

                console.log(datosRespuesta.data);
                this.setState({ Marcas: datosRespuesta.data.items });
            })
            .catch(console.log);
        //window.location.href = '/'; window.localStorage.clear();
    }
    ListarArea(page) {

        axios.get(Api + "configuration/area?page=" + page, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((datosRespuesta) => {
                Swal.close();

                console.log(datosRespuesta.data);
                this.setState({ Area: datosRespuesta.data.items });
            })
            .catch(console.log);
        //window.location.href = '/'; window.localStorage.clear();
    }
    ListarSubArea(page) {

        axios.get(Api + "configuration/area?page=" + page, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((datosRespuesta) => {
                Swal.close();

                console.log(datosRespuesta.data);
                this.setState({ Subarea: datosRespuesta.data.items });
            })
            .catch(console.log);
        //window.location.href = '/'; window.localStorage.clear();
    }

    pasar = (id) => {

        axios.get(Api + "configuration/item/" + id)
            .then((datosRespuesta) => {
                console.log(datosRespuesta.data.data.item);

                this.setState({ Modulos: datosRespuesta.data.data.item });
                var fila = datosRespuesta.data.data.item;
                document.getElementById("id").value = fila.id;
                document.getElementById("code").value = fila.code;
                document.getElementById("codeext").value = fila.codeext;
                document.getElementById("v1").value = fila.v1;
                document.getElementById("v2").value = fila.v2;
                document.getElementById("v3").value = fila.v3;
                document.getElementById("subarea").value = fila.subarea;
                document.getElementById("description").value = fila.description;
                document.getElementById("sudescription_short").value = fila.sudescription_short;
                document.getElementById("uemp2xtendido").value = fila.uemp2xtendido;
                document.getElementById("tendidosxpallets").value = fila.tendidosxpallets;
                document.getElementById("undxpalet").value = fila.undxpalet;
                document.getElementById("palletsxcontenedor").value = fila.palletsxcontenedor;
                document.getElementById("unxempaque").value = fila.unxempaque;
                document.getElementById("unxempaque2").value = fila.unxempaque2;
                document.getElementById("gtin13").value = fila.gtin13;
                document.getElementById("gtin13_2").value = fila.gtin13_2;
                document.getElementById("gtin13_3").value = fila.gtin13_3;
                document.getElementById("gtin14").value = fila.gtin14;
                document.getElementById("gs1128").value = fila.gs1128;
                document.getElementById("gs1128mayor").value = fila.gs1128mayor;
                document.getElementById("control_lote").checked = fila.control_lote;
                document.getElementById("control_fecha_v").checked = fila.control_fecha_v;
                document.getElementById("control_calidad").checked = fila.control_calidad;
                document.getElementById("control_serial").checked = fila.control_serial;
                document.getElementById("control_especial").checked = fila.control_especial;
                document.getElementById("temp").value = fila.temp;
                document.getElementById("vida_util").value = fila.vida_util;
                document.getElementById("dias_cuarentena").value = fila.dias_cuarentena;
                document.getElementById("brand").value = fila.brand;
                document.getElementById("category").value = fila.category;
                document.getElementById("subcategory").value = fila.subcategory;
                document.getElementById("fragility").value = fila.fragility;
                document.getElementById("owner").value = fila.owner;
                document.getElementById("supplier").value = fila.supplier;

                document.getElementById("width_UD").value = fila.width_UD;
                document.getElementById("height_UD").value = fila.height_UD;
                document.getElementById("length_UD").value = fila.length_UD;
                document.getElementById("weight_UD").value = fila.weight_UD;
                document.getElementById("width_E1").value = fila.width_E1;
                document.getElementById("height_E1").value = fila.height_E1;
                document.getElementById("length_E1").value = fila.length_E1;
                document.getElementById("weight_E1").value = fila.weight_E1;
                document.getElementById("width_E2").value = fila.width_E2;
                document.getElementById("height_E2").value = fila.height_E2;
                document.getElementById("length_E2").value = fila.length_E2;
                document.getElementById("weight_E2").value = fila.weight_E2;
                document.getElementById("estiba").value = fila.estiba;

            })
            .catch(console.log);
    }
    Nuevo = () => {
        this.setState({ Modulos: '' });
        document.getElementById("form_roles").reset();
        document.getElementById("description").value = '';
    }

    save(sw) {
        var datos = new FormData(document.getElementById("form_roles"));
        console.log(datos);
        axios.post(Api + "configuration/item", datos)
            .then((datosRespuesta) => {
                console.log(datosRespuesta)
                this.Listar(1);
                this.Nuevo();
                document.getElementById("form_roles").reset();
                if (datosRespuesta.data.status == 'success') {
                    Swal.fire(
                        'Registro!',
                        datosRespuesta.data.msj,
                        'success'
                    )
                } else {
                    Swal.fire(
                        'Registro!',
                        datosRespuesta.data.msj,
                        'error'
                    )
                }

                if (sw == 2) {
                    const closeButton = document.querySelector('[data-bs-dismiss="modal"]');
                    closeButton.click();
                }
            },
                (error) => {
                    // Manejar el error
                    console.log(error);
                    if (error.response) {
                        // El servidor respondió con un código de estado diferente a 2xx
                        console.log(error.response.status); // Imprimir el código de estado (por ejemplo, 400)
                        console.log(error.response.data.message); // Imprimir los datos de la respuesta (por ejemplo, mensaje de error)
                        //const  da = error.response.message;

                        if (error.response.data.message['website']) {
                            Swal.fire("Mensaje", "Debes de escribir un url valida", "error");
                            return false;
                        }
                        if (error.response.data.message['email']) {
                            Swal.fire("Mensaje", "Debes de escribir un correo valido", "error");
                            return false;
                        }
                        Swal.fire("Debes de llenar todos los campos", "", "error");
                    } else {
                        // Ocurrió un error antes de que se pudiera recibir una respuesta del servidor
                        Swal.fire("Erro al conetarse con el servidor", "", "error");
                    }
                })
            .catch(console.log('james'))
    }
    update() {
        var datos = new FormData(document.getElementById("form_roles"));
        console.log(datos);
        var id = document.getElementById("id").value;
        axios.patch(Api + "configuration/item/" + id, datos)
            .then((datosRespuesta) => {
                console.log(datosRespuesta)
                this.Listar(1);
                this.Nuevo();
                document.getElementById("form_roles").reset();
                if (datosRespuesta.data.status == 'success') {
                    Swal.fire(
                        'Registro!',
                        'Se actualizo con exito',
                        'success'
                    )
                } else {
                    Swal.fire(
                        'Registro!',
                        'No hubo ningun cambio',
                        'error'
                    )
                }
                const closeButton = document.querySelector('[data-bs-dismiss="modal"]');
                closeButton.click();
            })
            .catch(console.log)
    }
    componentDidMount() {
        this.Listar(1);
        this.Listarcategoria(1);
        this.Listarcategoria2(1);
        this.ListarTipos(1);
        this.Listarcompany(1);
        this.Listarmarcas(1);
        this.ListarArea(1);
        this.ListarSubArea(1);
        this.Listarstibas(1);
    }
    render() {
        const { load, Registros, pagina, ultimo, actual, Modulos, Marcas, Categoria, Categoria2, Tipos, Company ,Area,Subarea,Stibas} = this.state;
        return (
            <div className="content">
                <div className="modal fade" id="permisoRolesModal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="nuevoPacienteSubLabel" aria-hidden="true">
                    <div className="modal-dialog  modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="permisoRolesModalLabel">Formulario de Registro</h4>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <form id="form_roles">
                                                <ul className="nav nav-tabs" role="tablist">
                                                    <li className="nav-item">
                                                        <a className="nav-link active" data-bs-toggle="tab" href="#basico">Basico</a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className="nav-link" data-bs-toggle="tab" href="#Medidas">Medidas</a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className="nav-link" data-bs-toggle="tab" href="#Almacen">Almacen</a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className="nav-link" data-bs-toggle="tab" href="#Calidad">Calidad</a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className="nav-link" data-bs-toggle="tab" href="#Clasificacion">Clasificacion</a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className="nav-link" data-bs-toggle="tab" href="#Formato">Formato Empaque</a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className="nav-link" data-bs-toggle="tab" href="#Controles">Controles</a>
                                                    </li>
                                                </ul>

                                                {/* Tab panes */}
                                                <div className="tab-content">
                                                    <div id="basico" className="container tab-pane active">
                                                        <h3>Info Basica</h3>
                                                        <div className='row'>


                                                            <div className="col-md-6">
                                                                <label htmlFor="id_modulo">Codigo</label>
                                                                <input className={"form-control form-control-sm"} type="text" name="code" id="code" placeholder="" />
                                                                <input className={"form-control form-control-sm"} type="hidden" name="id" id="id" placeholder="" />
                                                                <input className={"form-control form-control-sm"} type="hidden" name="account" id="account" value="1" />
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label htmlFor="id_modulo">Codigo Externo</label>
                                                                <input className={"form-control form-control-sm"} type="text" name="codeext" id="codeext" placeholder="" />
                                                            </div>
                                                            <div className="col-md-12">
                                                                <label htmlFor="id_modulo">Descripcion</label>
                                                                <textarea className={"form-control form-control-sm"} name="description" id="description" placeholder="" ></textarea>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <label htmlFor="id_modulo">Descripcion Corta</label>
                                                                <input className={"form-control form-control-sm"} type="text" name="sudescription_short" id="sudescription_short" placeholder="" />
                                                            </div>
                                                            <div className="col-md-4">
                                                                <label htmlFor="id_modulo">v1</label>
                                                                <input className={"form-control form-control-sm"} type="text" name="v1" id="v1" placeholder="" />
                                                            </div>
                                                            <div className="col-md-4">
                                                                <label htmlFor="id_modulo">v2</label>
                                                                <input className={"form-control form-control-sm"} type="text" name="v2" id="v2" placeholder="" />
                                                            </div>
                                                            <div className="col-md-4">
                                                                <label htmlFor="id_modulo">v3</label>
                                                                <input className={"form-control form-control-sm"} type="text" name="v3" id="v3" placeholder="" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id="Medidas" className="container tab-pane fade">
                                                        <h3>Medidas y Peso</h3>
                                                        <div className='row'>
                                                            <div className="col-md-3">
                                                                <label htmlFor="id_modulo">Ancho UD</label>
                                                                <input className={"form-control form-control-sm"} type="text" name="width_UD" id="width_UD" placeholder="" />
                                                            </div>
                                                            <div className="col-md-3">
                                                                <label htmlFor="id_modulo">Alto UD</label>
                                                                <input className={"form-control form-control-sm"} type="text" name="height_UD" id="height_UD" placeholder="" />
                                                            </div>
                                                            <div className="col-md-3">
                                                                <label htmlFor="id_modulo">Largo UD</label>
                                                                <input className={"form-control form-control-sm"} type="text" name="length_UD" id="length_UD" placeholder="" />
                                                            </div>
                                                            <div className="col-md-3">
                                                                <label htmlFor="id_modulo">Peso UD</label>
                                                                <input className={"form-control form-control-sm"} type="text" name="weight_UD" id="weight_UD" placeholder="" />
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className="col-md-3">
                                                                <label htmlFor="id_modulo">Ancho E1</label>
                                                                <input className={"form-control form-control-sm"} type="text" name="width_E1" id="width_E1" placeholder="" />
                                                            </div>
                                                            <div className="col-md-3">
                                                                <label htmlFor="id_modulo">Alto E1</label>
                                                                <input className={"form-control form-control-sm"} type="text" name="height_E1" id="height_E1" placeholder="" />
                                                            </div>
                                                            <div className="col-md-3">
                                                                <label htmlFor="id_modulo">Largo E1</label>
                                                                <input className={"form-control form-control-sm"} type="text" name="length_E1" id="length_E1" placeholder="" />
                                                            </div>
                                                            <div className="col-md-3">
                                                                <label htmlFor="id_modulo">Peso E1</label>
                                                                <input className={"form-control form-control-sm"} type="text" name="weight_E1" id="weight_E1" placeholder="" />
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className="col-md-3">
                                                                <label htmlFor="id_modulo">Ancho E2</label>
                                                                <input className={"form-control form-control-sm"} type="text" name="width_E2" id="width_E2" placeholder="" />
                                                            </div>
                                                            <div className="col-md-3">
                                                                <label htmlFor="id_modulo">Alto E2</label>
                                                                <input className={"form-control form-control-sm"} type="text" name="height_E2" id="height_E2" placeholder="" />
                                                            </div>
                                                            <div className="col-md-3">
                                                                <label htmlFor="id_modulo">Largo E2</label>
                                                                <input className={"form-control form-control-sm"} type="text" name="length_E2" id="length_E2" placeholder="" />
                                                            </div>
                                                            <div className="col-md-3">
                                                                <label htmlFor="id_modulo">Peso E2</label>
                                                                <input className={"form-control form-control-sm"} type="text" name="weight_E2" id="weight_E2" placeholder="" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id="Almacen" className="container tab-pane fade">
                                                        <h3>Almacen</h3>
                                                        <div className='row'>
                                                            <div className="col-md-6">
                                                                <label htmlFor="id_modulo">Area Preferente</label>
                                                                
                                                                <select className="form-select" id="area" name="area" >
                                                                    <option value={""}>Seleccione</option>
                                                                    {
                                                                        Area.map((item) => (
                                                                            <option key={item.id} value={item.id}>{item.name}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label htmlFor="id_modulo">Sub Area preferente</label>
                                                                <select className="form-select" id="subarea" name="subarea" >
                                                                    <option value={""}>Seleccione</option>
                                                                    {
                                                                        Subarea.map((item) => (
                                                                            <option key={item.id} value={item.id}>{item.name}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label htmlFor="id_modulo">Muelle preferente:</label>
                                                                <input className={"form-control form-control-sm"} type="text" name="muelle_preferente" id="muelle_preferente" placeholder="" />
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label htmlFor="id_modulo">Picking location:</label>
                                                                <input className={"form-control form-control-sm"} type="text" name="picking_location" id="picking_location" placeholder="" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id="Calidad" className="container tab-pane fade">
                                                        <h3>Parametros de Calidad</h3>
                                                        <div className='row'>
                                                            <div className="col-md-6">
                                                                <label htmlFor="id_modulo">Vida util</label>
                                                                <input className={"form-control form-control-sm"} type="number" name="vida_util" id="vida_util" placeholder="" />
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label htmlFor="id_modulo">Dias cuarentena:</label>
                                                                <input className={"form-control form-control-sm"} type="number" name="dias_cuarentena" id="dias_cuarentena" placeholder="" />
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label htmlFor="id_modulo">Vlr tara:</label>
                                                                <input className={"form-control form-control-sm"} type="number" name="vlrtara" id="vlrtara" placeholder="" />
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label htmlFor="id_modulo">Tipo de despacho:</label>
                                                                <input className={"form-control form-control-sm"} type="number" name="dispacth" id="dispacth" placeholder="" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id="Clasificacion" className="container tab-pane fade">
                                                        <h3>Clasificacion</h3>
                                                        <div className='row'>
                                                            <div className="col-md-6">
                                                                <label htmlFor="id_modulo">Marca</label>
                                                                <select className="form-select" id="brand" name="brand" >
                                                                    <option value={""}>Seleccione</option>
                                                                    {
                                                                        Marcas.map((item) => (
                                                                            <option key={item.id} value={item.id}>{item.name}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label htmlFor="id_modulo">Categoria</label>
                                                                <select className="form-select" id="category" name="category" >
                                                                    <option value={""}>Seleccione</option>
                                                                    {
                                                                        Categoria.map((item) => (
                                                                            <option key={item.id} value={item.id}>{item.name}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label htmlFor="id_modulo">Subcategoria</label>
                                                                <select className="form-select" id="subcategory" name="subcategory" >
                                                                    <option value={""}>Seleccione</option>
                                                                    {
                                                                        Categoria2.map((item) => (
                                                                            <option key={item.id} value={item.id}>{item.name}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label htmlFor="id_modulo">Fragility</label>
                                                                <select className="form-select" id="fragility" name="fragility" >
                                                                    <option value={""}>Seleccione</option>
                                                                    {
                                                                        Tipos.map((item) => (
                                                                            <option key={item.id} value={item.id}>{item.name}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label htmlFor="id_modulo">Owner</label>
                                                                <select className="form-select" id="owner" name="owner" >
                                                                    <option value={""}>Seleccione</option>
                                                                    {
                                                                        Company.map((item) => (
                                                                            <option key={item.id} value={item.id}>{item.name}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label htmlFor="id_modulo">Supplier</label>
                                                                <select className="form-select" id="supplier" name="supplier" >
                                                                    <option value={""}>Seleccione</option>
                                                                    {
                                                                        Company.map((item) => (
                                                                            <option key={item.id} value={item.id}>{item.name}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id="Formato" className="container tab-pane fade">
                                                        <h3>Formato de Empaque</h3>
                                                        <div className='row'>
                                                            <div className="col-md-4">
                                                                <label htmlFor="id_modulo">uemp2xtendido</label>
                                                                <input className={"form-control form-control-sm"} type="number" name="uemp2xtendido" id="uemp2xtendido" placeholder="" />
                                                            </div>
                                                            <div className="col-md-4">
                                                                <label htmlFor="id_modulo">tendidosxpallets</label>
                                                                <input className={"form-control form-control-sm"} type="number" name="tendidosxpallets" id="tendidosxpallets" placeholder="" />
                                                            </div>
                                                            <div className="col-md-4">
                                                                <label htmlFor="id_modulo">undxpalet</label>
                                                                <input className={"form-control form-control-sm"} type="number" name="undxpalet" id="undxpalet" placeholder="" />
                                                            </div>
                                                            <div className="col-md-4">
                                                                <label htmlFor="id_modulo">palletsxcontenedor</label>
                                                                <input className={"form-control form-control-sm"} type="number" name="palletsxcontenedor" id="palletsxcontenedor" placeholder="" />
                                                            </div>
                                                            <div className="col-md-4">
                                                                <label htmlFor="id_modulo">unxempaque</label>
                                                                <input className={"form-control form-control-sm"} type="text" name="unxempaque" id="unxempaque" placeholder="" />
                                                            </div>
                                                            <div className="col-md-4">
                                                                <label htmlFor="id_modulo">unxempaque2</label>
                                                                <input className={"form-control form-control-sm"} type="text" name="unxempaque2" id="unxempaque2" placeholder="" />
                                                            </div>
                                                            <div className="col-md-4">
                                                                <label htmlFor="id_modulo">gtin13</label>
                                                                <input className={"form-control form-control-sm"} type="text" name="gtin13" id="gtin13" placeholder="" />
                                                            </div>
                                                            <div className="col-md-4">
                                                                <label htmlFor="id_modulo">gtin13_2</label>
                                                                <input className={"form-control form-control-sm"} type="text" name="gtin13_2" id="gtin13_2" placeholder="" />
                                                            </div>
                                                            <div className="col-md-4">
                                                                <label htmlFor="id_modulo">gtin13_3</label>
                                                                <input className={"form-control form-control-sm"} type="text" name="gtin13_3" id="gtin13_3" placeholder="" />
                                                            </div>
                                                            <div className="col-md-4">
                                                                <label htmlFor="id_modulo">gtin14</label>
                                                                <input className={"form-control form-control-sm"} type="text" name="gtin14" id="gtin14" placeholder="" />
                                                            </div>
                                                            <div className="col-md-4">
                                                                <label htmlFor="id_modulo">gs1128</label>
                                                                <input className={"form-control form-control-sm"} type="text" name="gs1128" id="gs1128" placeholder="" />
                                                            </div>
                                                            <div className="col-md-4">
                                                                <label htmlFor="id_modulo">gs1128mayor</label>
                                                                <input className={"form-control form-control-sm"} type="text" name="gs1128mayor" id="gs1128mayor" placeholder="" />
                                                            </div>
                                                            <div className="col-md-4">
                                                                <label htmlFor="id_modulo">Estiba</label>
                                                                <select className="form-select" id="estiba" name="estiba" >
                                                                    <option value={""}>Seleccione</option>
                                                                    {
                                                                        Stibas.map((item) => (
                                                                            <option key={item.id} value={item.id}>{item.description}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id="Controles" className="container tab-pane fade">
                                                        <h3>Controles</h3>
                                                        <div className="row g-3">




                                                            <div className="col-md-4">
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" id="control_lote" name="control_lote" />
                                                                    <label className="form-check-label" >
                                                                        Control lote
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" id="control_fecha_v" name="control_fecha_v" />
                                                                    <label className="form-check-label" >
                                                                        Control fecha v
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" id="control_calidad" name="control_calidad" />
                                                                    <label className="form-check-label" >
                                                                        Control calidad
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" id="control_serial" name="control_serial" />
                                                                    <label className="form-check-label" >
                                                                        Control serial
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" id="control_especial" name="control_especial" />
                                                                    <label className="form-check-label" >
                                                                        Control especial
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <label htmlFor="id_modulo">Temp</label>
                                                                <input className={"form-control form-control-sm"} type="number" name="temp" id="temp" placeholder="" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                            </form>
                                        </div>


                                    </div>


                                    <div className="modal-footer">
                                        {!Modulos.id ?
                                            <><button type="button" className="btn btn-primary btn-sm" onClick={() => this.save(1)}>Guardar y Agregar otro</button><button type="button" className="btn btn-primary btn-sm" onClick={() => this.save(2)}>Guardar</button></> :
                                            <button type="button" className="btn btn-primary btn-sm" onClick={() => this.update()}>Editar</button>
                                        }

                                        <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal">Cerrar</button>
                                    </div>
                                </div>
                                <hr />

                            </div>
                        </div>
                    </div>

                </div>
                <div className="container-fluid">
                    <br />
                    <div className="card">
                        <div className="card-header">
                            <button
                                type="button"
                                className="btn btn-success btn-sm"
                                data-bs-toggle='modal' data-bs-target="#permisoRolesModal"
                                onClick={() => this.Nuevo()}
                            >
                                <FontAwesomeIcon style={{ color: '#FFFFFF' }} icon={faPlus} />
                                &nbsp;&nbsp;Nuevo Registro
                            </button>
                        </div>
                        <div className="card-body">
                            <h4> Productos</h4>
                            <hr />
                            <div className="row">
                                <div className="col-xl-3">
                                    <input style={{ textTransform: 'uppercase' }} type="text" id="bnombre" name="bnombre" placeholder="Buscar" className="form-control form-control-sm" />
                                </div>
                                <div className="col-xl-3">
                                    <button id="bt" name="bt" className="btn btn-secondary btn-sm" onClick={() => this.Listar(1)}><FontAwesomeIcon icon={faFilter} />&nbsp;&nbsp;Filtrar</button>
                                </div>
                            </div>
                            <br></br>
                            <div className="row">
                                <table className="table table-hover table-condensed table-sm">
                                    <thead className="thead-inverse">
                                        <tr>
                                            <th className="align-middle">CODIGO</th>
                                            <th className="align-middle">CODIGO EXT</th>
                                            <th className="align-middle">DESCRIPCIÓN</th>
                                            <th className="align-middle">DESCRIPCIÓN CORTA</th>
                                            <th className="align-middle">OPCIONES</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {load}
                                        {
                                            Registros.map((it) => (
                                                <tr key={it.code}>
                                                    <td>{it.code}</td>
                                                    <td>{it.codeext}</td>
                                                    <td>{it.description}</td>
                                                    <td>{it.sudescription_short}</td>
                                                    <td>
                                                        <div className="btn-group" role="group" aria-label="">

                                                            <button
                                                                type="button"
                                                                className="btn btn-danger btn-sm"
                                                                onClick={() =>
                                                                    this.borrarRegistros(it.id)
                                                                }
                                                            >
                                                                <FontAwesomeIcon icon={faTrashAlt} />
                                                            </button>&nbsp;&nbsp;&nbsp;
                                                            <button
                                                                type="button"
                                                                className="btn btn-info btn-sm"
                                                                data-bs-toggle='modal' data-bs-target="#permisoRolesModal"
                                                                onClick={() =>
                                                                    this.pasar(it.id)
                                                                }
                                                            >
                                                                <FontAwesomeIcon style={{ color: '#FFFFFF' }} icon={faEdit} />
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <nav aria-label="Page navigation example">
                                <ul className="pagination">
                                    <li className="page-item"><a className="page-link" href="#" onClick={() => this.Listar(1)}><FontAwesomeIcon icon={faAngleDoubleLeft} /></a></li>
                                    <li className="page-item"><a className="page-link" href="#" onClick={() => this.Listar(actual - 1)}><FontAwesomeIcon icon={faAngleLeft} /></a></li>
                                    {(() => {
                                        let p = [];
                                        p.push(<li className="page-item" key={actual}><a className="page-link" href="#" onClick={() => this.BuscarRol(actual)}>{actual}</a></li>);
                                        return p;
                                    })()}
                                    <li className="page-item"><a className="page-link" href="#" onClick={() => this.Listar(actual + 1)}><FontAwesomeIcon icon={faAngleRight} /></a></li>
                                    <li className="page-item"><a className="page-link" href="#" onClick={() => this.Listar(ultimo)}><FontAwesomeIcon icon={faAngleDoubleRight} /></a></li>
                                </ul>
                            </nav>
                        </div>
                        <div className="card-footer text-muted">
                            <b>Pagina</b> <span className="badge bg-primary">{actual}</span> <b>de</b> <span className="badge bg-primary">{ultimo}</span> | <b>Registros</b> <span className="badge bg-success">{pagina}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Listar;