import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./components/Login";
import Header from './layout/Header';
import Menu from './layout/Menu';
import Footer from './layout/Footer';

import Home from '../src/components/home/Index';

import Usuarios from '../src/components/usuarios/Index';

import Categorias from '../src/components/Category/Index';
import Subcategorias from '../src/components/Subcategory/Index';
import Clientes from './components/Customer/Index';
import Marcas from './components/Brand/Index';
import Mapas from './components/Maps/Index';
import Tipos from './components/Type/Index';
import Company from './components/Company/Index';
import Characteristic from './components/Characteristic/Index';
import Item from './components/Item/Index';
import Classification from './components/Classification/Index';
import Countries from './components/Countries/Index';
import Driver from './components/Driver/Index';
import Parking from './components/Parking/Index';
import Expirationmargin from './components/Expirationmargin/Index';
import Vehicle from './components/Vehicle/Index';
import Almacen from './components/Store/Index';
import Center from './components/Center/Index';
import Area from './components/Area/Index';
import Subarea from './components/Subarea/Index';
import City from './components/City/Index';
import Regione from './components/Region/Index';
import Stibas from './components/Stibas/Index';
import Localizaciones from './components/Localizacion/Index';
import Daysweek from './components/Daysweek/Index';
import Festivo from './components/Festivo/Index';
import Franja from './components/Franjas/Index';
import Entidad from './components/Entidades/Index';
import Proceso from './components/Procesos/Index';
import OrdenCompra from './components/OrdenCompra/Index';
import ListaOrdenCompra from './components/OrdenCompra/Listado';
import ListaIngresos from './components/Ingresos/Listado';
import Ingresos from './components/Ingresos/Index';
import Porteria from './components/Porteria/Index';
import ListaPorteria from './components/Porteria/Listado';
function App() {
  const ux = localStorage.getItem("username");
  return (
    
    <Router>
      {ux != null ? <Header /> : ''}
      {ux != null ? <Menu /> : ''}
      {ux != null ? <Route exact path="/" component={Home}></Route> :
        <Route exact path="/" component={Login}></Route>}


        <Route exact path="/home" component={ux != null ? Home : Login}></Route> 

       <Route exact path="/MasterCategorias" component={ux != null ? Categorias : Login}></Route> 
       <Route exact path="/MasterSubCategorias" component={ux != null ? Subcategorias : Login}></Route> 
       <Route exact path="/MasterClientes" component={ux != null ? Clientes : Login}></Route>
       <Route exact path="/MasterMarcas" component={ux != null ? Marcas : Login}></Route>
       <Route exact path="/MasterMapas" component={ux != null ? Mapas : Login}></Route>
       <Route exact path="/MasterTipos" component={ux != null ? Tipos : Login}></Route>
       <Route exact path="/MasterCompany" component={ux != null ? Company : Login}></Route>
       <Route exact path="/MasterCaracteristicas" component={ux != null ? Characteristic : Login}></Route>
       <Route exact path="/MasterProductos" component={ux != null ? Item : Login}></Route>
       <Route exact path="/MasterClasificacion" component={ux != null ? Classification : Login}></Route>
       <Route exact path="/MasterPaises" component={ux != null ? Countries : Login}></Route>
       <Route exact path="/MasterConductores" component={ux != null ? Driver : Login}></Route>
       <Route exact path="/MasterParqueadero" component={ux != null ? Parking : Login}></Route>
       <Route exact path="/Expirationmargin" component={ux != null ? Expirationmargin : Login}></Route>
       <Route exact path="/MasterVehiculos" component={ux != null ? Vehicle : Login}></Route>

       <Route exact path="/MasterStore" component={ux != null ? Almacen : Login}></Route>
       <Route exact path="/MasterCenter" component={ux != null ? Center : Login}></Route>
       <Route exact path="/MasterArea" component={ux != null ? Area : Login}></Route>
       <Route exact path="/MasterSubarea" component={ux != null ? Subarea : Login}></Route>
       <Route exact path="/MasterCity" component={ux != null ? City : Login}></Route>
       <Route exact path="/MasterRegion" component={ux != null ? Regione : Login}></Route>
       <Route exact path="/Estibas" component={ux != null ? Stibas : Login}></Route>
       <Route exact path="/Localizaciones" component={ux != null ? Localizaciones : Login}></Route>
       <Route exact path="/daysweek" component={ux != null ? Daysweek : Login}></Route>
       <Route exact path="/festivos" component={ux != null ? Festivo : Login}></Route>
       <Route exact path="/franjas" component={ux != null ? Franja : Login}></Route>
       <Route exact path="/entidades" component={ux != null ? Entidad : Login}></Route>
       <Route exact path="/procesos" component={ux != null ? Proceso : Login}></Route>
       <Route exact path="/ordencompra" component={ux != null ? OrdenCompra : Login}></Route>
       <Route exact path="/listaorden" component={ux != null ? ListaOrdenCompra : Login}></Route>
       <Route exact path="/listaingresos" component={ux != null ? ListaIngresos : Login}></Route>
       <Route exact path="/ingreso" component={ux != null ? Ingresos : Login}></Route>
       <Route exact path="/porteria" component={ux != null ? Porteria : Login}></Route>
       <Route exact path="/listadoporteria" component={ux != null ? ListaPorteria : Login}></Route>
      {ux != null ? <Footer /> : ''}
    </Router>
  );
}

export default App;
