import React from 'react';
import { Link } from 'react-router-dom';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios';
import Swal from 'sweetalert2';
import Api from "../services/Api";

const authAxios = axios.create({
  baseURL: Api,
  headers: {
    Authorization: `Bearer ${localStorage.getItem('token')}`
  }
});

export default function Header() {
  function logout() {
    
      authAxios.post("auth/logout").then(console.log).catch(console.log);
      window.location.href = '/'; window.localStorage.clear();
   
  }
  return (
    <nav className="main-header navbar navbar-expand navbar-white navbar-light">
      {/* Left navbar links */}
      <ul className="navbar-nav">
        <li className="nav-item">
          <a className="nav-link" data-widget="pushmenu" href="#" role="button">
            <FontAwesomeIcon style={{ marginRight: 15 }} icon={faBars} />
          </a>
        </li>
      </ul>
      {/* Right navbar links */}
      <ul className="navbar-nav ml-auto">
        <li className="nav-item">
          <Link
            className="nav-link"
            data-widget="control-sidebar"
            data-slide="true"
            onClick={logout}
            role="button"
          >
             
            <FontAwesomeIcon style={{ marginRight: 30 }} title="Salir" icon={faSignOutAlt} />
           
          </Link>
        </li>
      </ul>
    </nav>
  );
}