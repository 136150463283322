import React, { Component } from 'react';
import Swal from 'sweetalert2';

import { Link } from "react-router-dom";
import authAxios from "../../services/Token";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight, faEdit, faFilter, faPlus, faTrashAlt, faCheck, faPowerOff, faPrint } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import Api from "../../services/Api";
import ModalCliente from '../Modales/Clientes';
import ModalProveedor from '../Modales/Proveedor';
import ModalProductos from '../Modales/Productos';
import ModalUsuarios from '../Modales/Usuarios';
import ModalAlmacen from '../Modales/Almacenes';
import ModalOrden from '../Modales/Orden';
import ModalActividad from '../Modales/Actividad';
import ModalOrdenDetalle from '../Modales/OrdenCompraDetalle';
import ModalVehiculo from '../Modales/Vehiculo';
import ModalConductor from '../Modales/Conductor';
import ModalParqueadero from '../Modales/Parqueadero';
import ModalLocalizacion from '../Modales/Localizacion';
import { PDFViewer } from '@react-pdf/renderer';
import InvoicePDF from './Pdf';

class Listar extends Component {
    
    state = {
        load: '',
        datosCargados: false,
        Registros: [],
        RegistrosOrden: [],
        Modulos: [],
        Relacion: [],
        Categoria:[],
        Unidades:[],
        Buscador:'0',
        Totales:0,
        Emegergente: '',
        Aprobado:false,
        Disabled:false,
        IdOrden: window.localStorage.getItem("order"),
        Encabezado:[],
        DatosCli:[],
        DatosPro:[],
        DatosCen:[],
        IdOrdenIng:window.localStorage.getItem("porteria_ing"),
    }
    miFuncion = (dato) => {
        // Lógica de la función que recibe el dato
        this.setState({ IdOrden: dato});
        this.VerRegistro();
        //this.Listar();
      }
      Buscar(option){
        this.setState({ Buscador: option});
        let componentToRender;
        const {Unidades}= this.state;
        if (option === 'Vehiculo') {
            componentToRender = <ModalVehiculo />;
            } 
        if (option === 'Conductor') {
                componentToRender = <ModalConductor />;
            }
            if (option === 'Parqueadero') {
                componentToRender = <ModalParqueadero />;
            }
            if (option === 'Localizacion') {
                componentToRender = <ModalLocalizacion />;
            }
            if (option === 'Actividad') {
                componentToRender = <ModalActividad />;
            }
        if (option === 'Cliente') {
        componentToRender = <ModalCliente />;
        } 
        if (option === 'Proveedor') {
        componentToRender = <ModalProveedor />;
        } 
        if (option === 'Productos') {
            componentToRender = <ModalProductos />;
            } 
        if (option === 'Usuario') {
                componentToRender = <ModalUsuarios />;
        } 
      
        if (option === 'Almacen') {
            componentToRender = <ModalAlmacen />;
        } 
        if (option === 'Orden') {
            componentToRender = <ModalOrden llamarFuncion={this.miFuncion}/>;
         } 
         if (option === 'OrdenDetalle') {
            componentToRender = <ModalOrdenDetalle  Unidades={Unidades}/>;
         } 
        this.setState({ Emegergente: componentToRender});
    }
    VerRegistro = () => {
        const { IdOrden} = this.state;
        var ord = window.localStorage.getItem("porteria_ing");
        //document.getElementById("number_ing").value= ord;
        axios.get(Api+"checkin/income/"+ord)
            .then((datosRespuesta) => {
                //console.log(datosRespuesta);
              
                //this.setState({ Modulos: datosRespuesta.data.data.item });
                var fila = datosRespuesta.data.data.item;
                document.getElementById("id").value= fila.id;
                document.getElementById("order").value= fila.order;
                document.getElementById("observation").value=fila.observation;
                //document.getElementById("aprrove").checked=fila.aprrove;
                document.getElementById("date").value=fila.date;
            
                document.getElementById("value").value=fila.value;
                document.getElementById("user").value=fila.user;
                document.getElementById("vehicle").value=fila.vehicle;
                document.getElementById("driver").value=fila.driver;
                document.getElementById("parking").value=fila.parking;
                document.getElementById("Location").value=fila.Location;
               
                this.Vehiculo(fila.vehicle);
                this.Conductor(fila.driver);
                this.Parqueadero(fila.parking);
                this.Usuarios(fila.user);
                this.Localizacion(fila.Location);
                this.Orden(fila.order);
            })
            .catch(console.log);
    }
   
    Vehiculo = (id) => {

        axios.get(Api+"vehicle/"+id)
            .then((datosRespuesta) => {
                console.log(datosRespuesta);
                var fila = datosRespuesta.data.data.item;
                document.getElementById("vehicle").value= fila.id;
                document.getElementById("vehicle_name").value= fila.code;
                this.setState({ DatosPro: fila });
            })
            .catch(console.log);
    }
    Conductor = (id) => {

        axios.get(Api+"driver/"+id)
            .then((datosRespuesta) => {
                console.log(datosRespuesta);
                var fila = datosRespuesta.data.data.item;
                document.getElementById("driver_name").value= fila.firstname+' '+fila.lastname;
            })
            .catch(console.log);
    }
    Parqueadero = (id) => {

        axios.get(Api+"parking/"+id)
            .then((datosRespuesta) => {
                console.log(datosRespuesta);
                var fila = datosRespuesta.data.data.item;

                document.getElementById("parking_name").value = fila.reference;
    
                this.setState({ DatosCen: fila });
            })
            .catch(console.log);
    }
    Usuarios = (id) => {

        axios.get(Api+"users/user/"+id)
            .then((datosRespuesta) => {
                console.log("datos del usuario");
                console.log(datosRespuesta.data.data);
                var fi = datosRespuesta.data.data.item;
                document.getElementById("user_name").value= fi.email;
            })
            .catch(console.log);
    }
    Localizacion = (id) => {

        axios.get(Api+"configuration/location/"+id)
            .then((datosRespuesta) => {
                var fi = datosRespuesta.data.data.item;
                document.getElementById("Location_name").value= fi.code;
            })
            .catch(console.log);
    }
    Orden = (id) => {

        axios.get(Api+"checkin/order/"+id)
            .then((datosRespuesta) => {
                var fi = datosRespuesta.data.data.item;
                document.getElementById("order_name").value= fi.number;
            })
            .catch(console.log);
    }

    Nuevo = () => {
        document.getElementById("id0").value = '';
        document.getElementById("value0").value = '';
        document.getElementById("actividad0").value = '';
        document.getElementById("actividad_name0").focus();
        document.getElementById("observation0").value = '';
        document.getElementById("url0").value = '';
        document.getElementById("date0").value = '';
    
    }
    borrarRegistros = (id) => {
        Swal.fire({
            title: 'Está seguro de Eliminar el registro?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Borrar',
            denyButtonText: `Cancelar`,
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(Api+"checkin/incomedetail/" + id)
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta);
                        Swal.fire('Borrado!', 'Se ha borrado con exito', 'success')
                        this.Listar();
                    })
                    .catch(console.log);
            } else if (result.isDenied) {
                Swal.fire('Cancelado', '', 'info')
            }
        })
    };
    Listar() {
        const { IdOrdenIng} = this.state;
        console.log('id porteria'+IdOrdenIng);
        axios.get(Api+"checkin/incomedetail?id_income="+IdOrdenIng, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          })
            .then((datosRespuesta) => {
                console.log('datos de ingreso');
                console.log(datosRespuesta.data);
                this.setState({ Registros: datosRespuesta.data.items });
                var listado = datosRespuesta.data.items;

                const Actividad = (id,it) => {

                    axios.get(Api+"configuration/activity/"+id, {
                        method: "GET",
                        headers: {
                          "Content-Type": "application/json",
                        },
                      })
                        .then((datosRespuesta) => {
                            console.log('nombre actividad ');
                            console.log(datosRespuesta.data.data.item.name);
                   
                            //return  datosRespuesta.data.data.item.name;
                            document.getElementById("actividad_name"+it).value=datosRespuesta.data.data.item.name;
                           
                        })
                        .catch(console.log);
                    //window.location.href = '/'; window.localStorage.clear();
                }
                listado.map((item, index) => {
                   
                    //this.Listar(item.actividad);
                    const nombre =  Actividad(item.actividad,item.id);
                    //console.log(`Nombre del elemento ${nombre}`);
      
                });
               
            })
            .catch(console.log);
        //window.location.href = '/'; window.localStorage.clear();
    }
    
    save(sw) {
        var orden = document.getElementById("order").value;
        if(orden==''){
            Swal.fire(
                'Registro!',
                'Debes de seleccionar la orden',
                'error'
            )
            return false;
        }
        var datos = new FormData(document.getElementById("formulario"));
        console.log(datos);
        axios.post(Api+"checkin/income", datos)
            .then((datosRespuesta) => {
                console.log(datosRespuesta)
             
         
                if (datosRespuesta.data.status =='success') {
                    Swal.fire(
                        'Registro!',
                        'Se registro con exito',
                        'success'
                    )
                } else {
                    Swal.fire(
                        'Registro!',
                        datosRespuesta.data.msj,
                        'error'
                    )
                }

            },
            (error) => {
                // Manejar el error
                console.log(error);
                  if (error.response) {
                  // El servidor respondió con un código de estado diferente a 2xx
                  console.log(error.response.status); // Imprimir el código de estado (por ejemplo, 400)
                  console.log(error.response.data.message); // Imprimir los datos de la respuesta (por ejemplo, mensaje de error)
                  //const  da = error.response.message;
                   
                  if(error.response.data.message['observation']){
                    Swal.fire("Observacion", "Este campo no puede estar en blanco.", "error");
                    return false;
                  }
                  if(error.response.data.message['date']){
                    Swal.fire("Fecha", "Fecha con formato erróneo. Use uno de los siguientes formatos en su lugar: YYYY-MM-DD.", "error");
                    return false;
                  }
                  Swal.fire("Debes de llenar todos los campos", "", "error");
                } else {
                  // Ocurrió un error antes de que se pudiera recibir una respuesta del servidor
                  Swal.fire("Erro al conetarse con el servidor", "", "error");
                }
              })
            .catch(console.log('james'))
    }
    save_item(id) {
        const { IdOrdenIng} = this.state;
        var value = document.getElementById("value"+id).value;
        var actividad = document.getElementById("actividad"+id).value;
        var observation = document.getElementById("observation"+id).value;
        var url = document.getElementById("url"+id).value;
        var date = document.getElementById("date"+id).value;
        if(actividad == ''){
            Swal.fire(
                'Registro!',
                'Debes de seleccionar la actividad',
                'error'
            )
            return false;
        }
        var location = '';
        var datos = {income:IdOrdenIng,observation:observation,actividad:actividad,value:value,url:url,date:date};
        console.log(datos);
        var item_id = document.getElementById("id"+id).value;

        if(item_id==''){
            axios.post(Api+"checkin/incomedetail", datos)
            .then((datosRespuesta) => {
                console.log(datosRespuesta.data.data)
                //this.Listar(1);
                this.Nuevo();
                this.Listar();
                if (datosRespuesta.data.status =='success') {
                    Swal.fire(
                        'Registro!',
                        'Se agrego con exito',
                        'success'
                    )
                } else {
                    Swal.fire(
                        'Registro!',
                        'Se agrego con exito el items',
                        'error'
                    )
                }
                
               
            },
            (error) => {
                // Manejar el error
                console.log(error);
                  if (error.response) {
                  // El servidor respondió con un código de estado diferente a 2xx
                  console.log(error.response.status); // Imprimir el código de estado (por ejemplo, 400)
                  console.log(error.response.data.message); // Imprimir los datos de la respuesta (por ejemplo, mensaje de error)
                  
                  Swal.fire("Debes de llenar todos los campos", "", "error");
                } else {
                  // Ocurrió un error antes de que se pudiera recibir una respuesta del servidor
                  Swal.fire("Error al conetarse con el servidor", "", "error");
                }
              })
            .catch(console.log('james'))
        }else{
            axios.patch(Api+"checkin/incomedetail/"+item_id, datos)
            .then((datosRespuesta) => {
                console.log(datosRespuesta.data.data)
                //this.Listar(1);
                //this.Nuevo();
                this.Listar();
                if (datosRespuesta.data.status =='success') {
                    Swal.fire(
                        'Registro!',
                        'Se edito con exito',
                        'success'
                    )
                } else {
                    Swal.fire(
                        'Registro!',
                        'Se edito con exito el items',
                        'error'
                    )
                }

               
            },
            (error) => {
                // Manejar el error
                console.log(error);
                  if (error.response) {
                  // El servidor respondió con un código de estado diferente a 2xx
                  console.log(error.response.status); // Imprimir el código de estado (por ejemplo, 400)
                  console.log(error.response.data.message); // Imprimir los datos de la respuesta (por ejemplo, mensaje de error)
                  
                  Swal.fire("Debes de llenar todos los campos", "", "error");
                } else {
                  // Ocurrió un error antes de que se pudiera recibir una respuesta del servidor
                  Swal.fire("Erro al conetarse con el servidor", "", "error");
                }
              })
            .catch(console.log('james'))
        }
        
    }
    componentDidMount() {

        this.VerRegistro();
        this.Listar();
        
    }
    render() {
        const { RegistrosOrden,IdOrdenIng,load, Registros, pagina, ultimo, actual,DatosCli,DatosPro,DatosCen,Encabezado, IdOrden, Buscador,Emegergente,Aprobado,Disabled,Categoria,Unidades,Totales } = this.state;
        return (
            <div className="content">
                <div className="modal fade" id="ModalGlobal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="nuevoPacienteSubLabel" aria-hidden="true">
                    <div className="modal-dialog  modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="permisoRolesModalLabel">Buscar {Buscador}</h4>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            
                                            {Emegergente}
                                        </div>


                                    </div>


                                    <div className="modal-footer">
                                      
                                        <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal">Cerrar</button>
                                    </div>
                                </div>
                                <hr />

                            </div>
                        </div>
                    </div>

                </div>
                <div className="container-fluid">
                    <br />
                    <div className="card">
                        <div className="card-header">
                            Ingreso de porteria {IdOrdenIng}
                            <div style={{float: 'right'}}>
                                                    <button type='button' id='boton'  className='btn btn-success btn-sm' onClick={() =>this.save()} ><i class="fas fa-plus"></i> Generar Ingreso</button> 
                                                </div>
                        </div>
                       
                            <div className="card-body">
                               

                                <div className="row">
                                    <form id="formulario">
                             
                                        <div className="row g-3">
                                        <div className="col-md-3">
                                                <label htmlFor="id_modulo">Orden de Compra</label>
                                                <div class="input-group input-group-sm">
                                                <input className={"form-control form-control-sm"} type="hidden" name="id" id="id" placeholder="" />
                                                <input className={"form-control form-control-sm"} type="hidden" name="account" id="account" value="1" />
                                                <input type="hidden" class="form-control form-control-sm"  id='order' name='order'/>
                                                    <input type="text" class="form-control form-control-sm" disabled id='order_name' name='order_name'/>
                                                <span class="input-group-append">
                                                        <button type="button" class="btn btn-info btn-flat" disabled={Disabled} data-bs-toggle='modal' data-bs-target="#ModalGlobal"  onClick={() => this.Buscar('Orden')}><i class="fas fa-search"></i></button>
                                                    </span>
                                                    </div>
                                            </div>
                                           
                                            <div className="col-md-3">
                                                <label htmlFor="id_modulo">Costo</label>
                                                
                                                 
                                                    <input type="text" class="form-control form-control-sm" readonly id='value' name='value'/>
                                                    
                                            </div>
                                            <div className="col-md-3">
                                                <label htmlFor="id_modulo">Usuario</label>
                                                <div class="input-group input-group-sm">
                                                    <input type="hidden" class="form-control form-control-sm"  id='user' name='user'/>
                                                    <input type="text" class="form-control form-control-sm" disabled id='user_name' name='user_name'/>
                                                    <span class="input-group-append">
                                                    <button type="button" class="btn btn-info btn-flat"  data-bs-toggle='modal' data-bs-target="#ModalGlobal"  onClick={() => this.Buscar('Usuario')}><i class="fas fa-search"></i></button>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <label htmlFor="id_modulo">Vehiculo</label>
                                                <div class="input-group input-group-sm">
                                                    <input type="hidden" class="form-control form-control-sm"  id='vehicle' name='vehicle'/>
                                                    <input type="text" class="form-control form-control-sm" disabled id='vehicle_name' name='vehicle_name'/>
                                                    <span class="input-group-append">
                                                    <button type="button" class="btn btn-info btn-flat"  data-bs-toggle='modal' data-bs-target="#ModalGlobal"  onClick={() => this.Buscar('Vehiculo')}><i class="fas fa-search"></i></button>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <label htmlFor="id_modulo">Descripcion</label>
                                                <textarea className={"form-control form-control-sm"} name="observation"  id="observation" placeholder="" ></textarea>
                                            </div>
                                            <div className="col-md-3">
                                                <label htmlFor="id_modulo">Conductor</label>
                                                <div class="input-group input-group-sm">
                                                    <input type="hidden" class="form-control form-control-sm"  id='driver' name='driver'/>
                                                    <input type="text" class="form-control form-control-sm" disabled id='driver_name' name='driver_name'/>
                                                    <span class="input-group-append">
                                                    <button type="button" class="btn btn-info btn-flat"  data-bs-toggle='modal' data-bs-target="#ModalGlobal"  onClick={() => this.Buscar('Conductor')}><i class="fas fa-search"></i></button>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <label htmlFor="id_modulo">Parqueadero</label>
                                                <div class="input-group input-group-sm">
                                                    <input type="hidden" class="form-control form-control-sm"  id='parking' name='parking'/>
                                                    <input type="text" class="form-control form-control-sm" disabled id='parking_name' name='parking_name'/>
                                                    <span class="input-group-append">
                                                    <button type="button" class="btn btn-info btn-flat"  data-bs-toggle='modal' data-bs-target="#ModalGlobal"  onClick={() => this.Buscar('Parqueadero')}><i class="fas fa-search"></i></button>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <label htmlFor="id_modulo">Localizacion</label>
                                                <div class="input-group input-group-sm">
                                                    <input type="hidden" class="form-control form-control-sm"  id='Location' name='Location'/>
                                                    <input type="text" class="form-control form-control-sm" disabled id='Location_name' name='Location_name'/>
                                                    <span class="input-group-append">
                                                    <button type="button" class="btn btn-info btn-flat"  data-bs-toggle='modal' data-bs-target="#ModalGlobal"  onClick={() => this.Buscar('Localizacion')}><i class="fas fa-search"></i></button>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <label htmlFor="id_modulo">Fecha de Registro</label>

                                                <input className={"form-control form-control-sm"} type="date" disabled name="date" id="date" placeholder="" />
                                            </div>
                                            
                                            



                                        </div>
                                    </form>
                                </div>
                            </div>
                       
                        <div className="card-footer text-muted">
                            <table className="table table-hover table-condensed table-sm">
                                <thead>
                                    <th style={{width:'150px'}}>Actividad</th>
                                    <th style={{width:'250px'}}>Observaciones</th>
                                    <th style={{width:'80px'}}>Valor</th>
                                    <th style={{width:'70px'}}>Fecha</th>
                                    <th>Documento</th>
                                 
                                    <th>Opciones</th>
                                </thead>
                                <tbody>
                                <tr>
                              <td>
                                  <div class="input-group input-group-sm">
                                  <input type="hidden" id={'id0'} name='amount' className='form-control form-control-sm'  />
                                      <input type="hidden"  id="actividad0" name='item' class="form-control form-control-sm" />
                                      <input type="text"  id="actividad_name0" name='item_code' class="form-control form-control-sm" />
                                      <span class="input-group-append">
                                          <button type="button" class="btn btn-info btn-flat" data-bs-toggle='modal' data-bs-target="#ModalGlobal"  onClick={() => this.Buscar('Actividad')}><i class="fas fa-search"></i></button>
                                      </span>
                                  </div>
                              </td>
                              <td> <input type="text" id="observation0" name='observation0' className='form-control form-control-sm' /> </td>
                              <td> <input type="text" id="value0" name='value0' className='form-control form-control-sm' /> </td>
                              <td> <input type="date" id="date0" name='date0' className='form-control form-control-sm' /> </td>
                              <td> <input type="text" id="url0" name='url0' className='form-control form-control-sm' /> </td>
                              <td> <button type='button' className='btn btn-success btn-sm' disabled={Disabled}  onClick={() =>this.save_item(0)} >[+]</button> </td>                           
                              </tr> 
                                        {
                                            Registros.map((item) => (   
                                                <tr key={item.id}>
                                                    <td>
                                                        <input type="hidden" id={'actividad'+item.id} name='amount' defaultValue={item.actividad} className='form-control form-control-sm'  disabled={Disabled}/>
                                                        <input type="text" id={'actividad_name'+item.id} name='amount'  className='form-control form-control-sm'  disabled={Disabled}/></td>
                                                    <td><input type="text" id={'observation'+item.id} name='amount' defaultValue={item.observation} className='form-control form-control-sm'  disabled={Disabled}/></td>
                                                    <td><input type="text" id={'value'+item.id} name='amount' defaultValue={item.value} className='form-control form-control-sm'  disabled={Disabled} /></td>
                                                    <td><input type="date" id={'date'+item.id} name='amount' defaultValue={item.date} className='form-control form-control-sm'  disabled={Disabled}/></td>   
                                                    <td>
                                                        <input type="text" id={'url'+item.id} name='location' defaultValue={item.url}  className='form-control form-control-sm'  disabled={Disabled}/>
                                                        </td>
                                                     <td>
                                                        <div className="btn-group" role="group" aria-label="">
                                                           
                                                            <button
                                                                type="button"
                                                                className="btn btn-danger btn-sm"
                                                                onClick={() =>
                                                                    this.borrarRegistros(item.id)
                                                                }
                                                                disabled={Disabled}
                                                            >
                                                                <FontAwesomeIcon icon={faTrashAlt} />
                                                            </button>&nbsp;&nbsp;&nbsp; 
                                                            <button
                                                                type="button"
                                                                className="btn btn-info btn-sm"
                                                                
                                                                onClick={() =>
                                                                    this.save_item(item.id)
                                                                }
                                                                disabled={Disabled}
                                                            >
                                                                <FontAwesomeIcon style={{ color: '#FFFFFF' }} icon={faPlus} />
                                                            </button>
                                                           
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    
                                </tbody>
                                <tr>
                                        <td colSpan={4}>Totales {Totales}</td>
                                        <td> <input className={"form-control form-control-sm"} type="text" name="value" id="value" value={Totales} placeholder="" /></td>
                                        <td></td>
                                    </tr>
                            </table>
                        </div>
                    </div>
                    
                </div>
                <div className="modal fade" id="modalImprimir" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="nuevoPacienteSubLabel" aria-hidden="true">
                    <div className="modal-dialog  modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="permisoRolesModalLabel">Detalle de la entrada</h4>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="card">
                                    <div className="card-body">
                                    <div className="row">
                                   
                                    </div>     
                                    </div>
                                    <div className="modal-footer">
                                      
                                        
                                        <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal">Cerrar</button>
                                    </div>
                                </div>
                                <hr />
                                
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        );
    }
}

export default Listar;