import React from "react";
import Api from "../services/Api";
import AGMLogo from "../images/AGM Desarrollos Logo.png";
import AMLLogo from "../images/American Lighting Logo.png";
import ESIPLogo from "../images/ESIP Logo.png";
import SEMAPPLogo from "../images/SEMAPP Logo.jpg";
import "../css/main.css";
import "../css/util.css";
import axios from "axios";
import Swal from "sweetalert2";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      usuario: "",
      password: "",
      errores: [],
    };
  }
  cambioValor = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState({ state, errores: [] });
  };
  verificarError(elemento) {
    return this.state.errores.indexOf(elemento) !== -1;
  }
  enviarDatos = (e) => {
    e.preventDefault();
    Swal.showLoading();
    const { usuario, password } = this.state;
    var errores = [];
    if (!usuario) {
      errores.push("error_usuario");
    }
    if (!password) {
      errores.push("error_password");
    }
    this.setState({ errores: errores });
    if (errores.length > 0) {
      return false;
    }
    var datosEnviar = { email: usuario, password: password };
    //console.log(JSON.stringify(datosEnviar));
    axios
      .post(Api + "token/", datosEnviar, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(
        (response) => {
          console.log(response.data);
          if (response.data) {
            window.localStorage.setItem("username", usuario);
            window.localStorage.setItem("nombre", "James Navarro");
            window.localStorage.setItem("idusuario", usuario);
            window.localStorage.setItem("token", response.data.access);
            window.location.href = "/home";
          } else {
            alert(response.data.error);
            Swal.close();
          }
        },
        (error) => {
          Swal.fire("Email o contraseña invalida", "", "error");
          //Swal.close();
        }
      );
  };
  componentDidMount() {
    document.getElementById("usuario").focus();
  }
  render() {
    const { usuario, password } = this.state;
    return (
      <div className="hold-transition login-page">
        <div className="login-box">
          <div className="card card-outline card-primary">
            <div className="card-header text-center">
              <img src="dist/img/wms.png" alt="logo" width={120} />
            </div>

            <div className="card-body">
              <p className="login-box-msg">MANAGER WMS</p>
              <form onSubmit={this.enviarDatos} id="formlogin">
                
                <div className="input-group mb-3">
                  <input
                    className={
                      (this.verificarError("error_usuario")
                        ? "is-invalid"
                        : "") + " form-control"
                    }
                    type="text"
                    name="usuario"
                    id="usuario"
                    placeholder="USUARIO"
                    aria-describedby="helpId"
                    onChange={this.cambioValor}
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-user" />
                    </div>
                  </div>
                  <small id="helpId" className="invalid-feedback">
                    Digite el Usuario
                  </small>
                </div>
                <div className="input-group mb-3">
                  <input
                    className={
                      (this.verificarError("error_password")
                        ? "is-invalid"
                        : "") + " form-control"
                    }
                    type="password"
                    name="password"
                    id="password"
                    placeholder="CONTRASEÑA"
                    aria-describedby="helpId"
                    onChange={this.cambioValor}
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-lock" />
                    </div>
                  </div>
                  <small id="helpId" className="invalid-feedback">
                    Digite la Contraseña
                  </small>
                </div>
                <div className="row text-center">
                  <div className="col-12">
                    <button
                      type="submit"
                      className="btn btn-primary btn-block btn-sm"
                    >
                      Iniciar Sesión
                    </button>
                  </div>
                </div>
              </form>
              <div>
                <div className="social-auth-links mt-2 mb-3">
                  <a href="#" className="btn btn-block btn-danger btn-sm">
                   Recuparar mi contraseña
                  </a>
                </div>
                <p className="mb-0">
                  <a href="register.html" className="text-center">
                    Registrarme
                  </a>
                </p>
                <div className="footer">
                  <label>Developer By Frozen</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Login;
